<template>
    <div class="process-editor mt-5">
        <portal to="appBarNavigationTitle" :order="1">
            <v-sheet 
                v-if="processingActivityHeadline"
                color="transparent"
                class="text-left text-no-wrap text-truncate"
                style="width:100%">{{ processingActivityHeadline }}</v-sheet>
        </portal>
        <portal to="appBarNavigationTitle" :order="2">
            <v-tooltip
                v-if="editorMode === 'singleEditMode'"
                :disabled="!updatedAt"
                nudge-left="-298"
                :z-index="100"
                left
                color="primary"
                content-class="v-tooltip--hide-border"
                transition="fade-transition"
            >
                <template v-slot:activator="{ on }">
                    <div
                        v-on="on"
                        class="white--text text-caption pulse"
                        :class="{
                            animated: recordHasUpdated,
                        }"
                    >
                        <span v-if="updatedAt"
                            >{{ $t('lastUpdated') }}:
                            {{ updatedTimeLeft }}</span
                        ><span v-else>&nbsp;</span>
                    </div>
                </template>
                <span
                    >{{ $t('lastUpdated') }}:
                    {{ updatedTime }}</span
                >
            </v-tooltip>
        </portal>
        <div v-if="!$wait.is('pageLoader')">
            <portal to="appBarNavigationAction">
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="d-inline-block">
                            <v-btn
                                color="white"
                                outlined
                                class="ma-2"
                                fab
                                small
                                :elevation="2"
                                @click="onCancel()"
                                :disabled="$wait.is('saveActivityForm')"
                            >
                                <v-icon v-on="on" v-text="'mdi-arrow-left'" />
                            </v-btn>
                        </div>
                    </template>
                    <span v-t="'vvtProcessEditor.backTooltip'" />
                </v-tooltip>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="d-inline-block">
                            <v-btn
                                color="white"
                                :class="{ 'primary--text': helpMode }"
                                :outlined="!helpMode"
                                class="ma-2"
                                fab
                                small
                                :elevation="2"
                                @click="toggleHelpMode()"
                            >
                            <v-icon
                                v-if="!helpMode"
                                v-text="'mdi-lightbulb-on-outline'" />
                            <v-icon
                                v-else
                                v-text="'mdi-lightbulb-on'" />
                            </v-btn>
                        </div>
                    </template>
                    <span v-text="toggleHelpTooltip" />
                </v-tooltip>
                <div v-if="hasAccessToWorkflow" class="d-inline-block ma-2">
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="d-inline-block">
                                <v-badge
                                    :value="hasWorkflowButtonBadget"
                                    color="warning"
                                    dot
                                    bordered
                                    light
                                    :offset-y="11"
                                    :offset-x="8"
                                >
                                    <v-btn
                                        color="white"
                                        :class="{ 'primary--text': workflowMode }"
                                        :outlined="!workflowMode"
                                        :disabled="!workflowEnabled"
                                        fab
                                        small
                                        :elevation="2"
                                        @click="workflowEnabled && toggleWorkflow()"
                                    >
                                        <v-icon
                                            v-if="!workflowMode"
                                            v-text="'mdi-timeline-check-outline'" />
                                        <v-icon
                                            v-else
                                            v-text="'mdi-timeline-check'" />
                                    </v-btn>
                                </v-badge>
                            </div>
                        </template>
                        <span v-text="toggleWorkflowTooltip" />
                    </v-tooltip>
                </div>
                <div class="d-inline-block ma-2">
                    <v-badge
                        :value="!canEdit ? false : modifiedByUser"
                        color="success"
                        bordered
                        dark
                        :offset-y="16"
                        :offset-x="11"
                        icon="mdi-asterisk"
                        class="v-badge-diff v-badge--save"
                        >
                        <v-tooltip bottom :color="canEdit ? 'primary' : 'grey'">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="d-inline-block">
                                    <v-btn
                                        color="white"
                                        outlined
                                        :loading="$wait.is('saveActivityForm')"
                                        :disabled="!canEdit || !modifiedByUser || $wait.is('saveActivityForm')"
                                        fab
                                        small
                                        :elevation="2"
                                        @click="validateFormAndSave() "
                                    >
                                        <v-icon v-text="'mdi-content-save-edit'" />
                                    </v-btn>
                                </div>
                            </template>
                            <span
                                v-if="canEdit"
                                v-t="'vvtProcessEditor.saveRecord'"
                            ></span>
                            <span
                                v-else
                                v-t="'notAuthorizedAction'"
                            ></span>
                        </v-tooltip>
                    </v-badge>
                </div>
            </portal>
            <portal to="appBarNavigationExtension">
                <v-tabs
                    v-model="tabsStepperModel"
                    :value="tabsStepperModel"
                    background-color="transparent"
                    color="primary"
                    light
                    center-active
                    :fixed-tabs="false"
                    :icons-and-text="false"
                    slider-color="primary"
                    :centered="false"
                    :height="53"
                    :slider-size="3"
                    class="py-0"
                    :mobile-breakpoint="mobileBreakpoint"
                    show-arrows
                >
                    <v-tab
                        v-for="n in steps"
                        :key="n"
                        :class="{'ml-8': n === 1 && !smallButton}"
                    >
                        <span
                            v-if="$vuetify.breakpoint.smAndUp"
                            class="mt-2 mb-1 text-center text-no-wrap text-truncate body-2"
                            style="width:100%"
                            :class="{'error--text text--lighten-2': stepErrors[n-1]}"
                        >{{ n }}. {{ $t('vvtProcessEditor.steps.' + n + '.tabTitle') }}</span>
                        <v-avatar
                            v-if="false"
                            :color="(n-1) === tabsStepperModel ? 'primary' : 'grey lighten-2'"
                            size="22"
                            >
                            <span class="subtitle-2" :class="{'error--text text--lighten-2': stepErrors[n-1], 'white--text': (n-1) === tabsStepperModel, 'primary--text': (n-1) !== tabsStepperModel}">{{ n }}</span>
                        </v-avatar>
                    </v-tab>
                </v-tabs>
            </portal>
            <v-container fluid>
                <v-row align="start">
                    <v-col
                        :order="2"
                        :order-lg="1"
                        :cols="editorColumns"
                    >
                        <v-tabs-items v-model="tabsStepperModel">
                            <v-tab-item>
                                <Step1
                                    :create="create"
                                    :template="template"
                                    :formScope="formScopes[0]"
                                    :recordLanguage="recordLanguage"
                                    :forcedLanguage="forcedLanguage"
                                    @on-changed-record-language="onChangedRecordLanguage($event)"
                                    @help-mode="helpModeField = $event"
                                />
                            </v-tab-item>
                            <v-tab-item>
                                <Step2
                                    :create="create"
                                    :template="template"
                                    :formScope="formScopes[1]"
                                    :forcedLanguage="forcedLanguage"
                                    @help-mode="helpModeField = $event"
                                />
                            </v-tab-item>
                            <v-tab-item>
                                <Step3
                                    :create="create"
                                    :template="template"
                                    :countryCode="countryCode"
                                    :formScope="formScopes[2]"
                                    :currentUiLanguage="currentUiLanguage"
                                    @help-mode="helpModeField = $event"
                                />
                            </v-tab-item>
                            <v-tab-item>
                                <Step4
                                    :create="create"
                                    :template="template"
                                    :formScope="formScopes[3]"
                                    @help-mode="helpModeField = $event"
                                />
                            </v-tab-item>
                            <v-tab-item>
                                <Step5
                                    :create="create"
                                    :template="template"
                                    :editorMode="editorMode"
                                    :countryCode="countryCode"
                                    :formScope="formScopes[4]"
                                    :currentUiLanguage="currentUiLanguage"
                                    :forcedLanguage="forcedLanguage"
                                    @help-mode="helpModeField = $event"
                                />
                            </v-tab-item>
                            <v-tab-item>
                                <Step6
                                    :create="create"
                                    :template="template"
                                    :editorMode="editorMode"
                                    :formScope="formScopes[5]"
                                    :company="company"
                                    :currentUiLanguage="currentUiLanguage"
                                    @help-mode="helpModeField = $event"
                                />
                            </v-tab-item>
                            <v-tab-item>
                                <Step7
                                    :create="create"
                                    :template="template"
                                    :formScope="formScopes[6]"
                                    :countryCode="countryCode"
                                    @help-mode="helpModeField = $event"
                                />
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                    <v-col
                        v-if="helpMode || workflowMode"
                        :order="1"
                        :order-lg="2"
                        :cols="sidebarColumns"
                        class="relative"
                    >
                        <InfoBar
                            :create="create"
                            :template="template"
                            :editorMode="editorMode"
                            :company="company"
                            :routeId="routeId"
                            :routeWorkflowTaskId="routeWorkflowTaskId"
                            :helpModeField="helpModeField"
                            :workflowEnabled="workflowEnabled"
                            :helpMode="helpMode"
                            :modifiedByUser="modifiedByUser"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <BaseConfirmationDialog
                v-model="baseConfirmation.dialog"
                :title="baseConfirmation.title"
                :text="baseConfirmation.text"
                :confirmationText="baseConfirmation.confirmationText"
                :cancelText="baseConfirmation.cancelText"
                :buttonColorConfirm="baseConfirmation.buttonColorConfirm"
                :buttonColorCancel="baseConfirmation.buttonColorCancel"
                :max-width="$vuetify.breakpoint.smAndDown ? '95%' : '30%'"
                @confirm="baseConfirmation.fnConfirm()"
                @cancel="baseConfirmation.fnCancel()"
                :dismissible="baseConfirmation.dismissible"
                :showCancel="baseConfirmation.showCancel"
                :loading="baseConfirmation.loading"
            />
            <v-snackbar
                v-model="feedbackSnackbar"
                :color="feedBackSnackbarColor"
                :timeout="5000"
                top
                class="ma-0 pa-0 mt-n3"
            >
                <v-alert
                    text
                    :color="feedBackSnackbarColor"
                    class="ma-n3"
                >
                    <template v-if="feedBackSnackbarIcon" v-slot:prepend>
                        <v-icon v-text="feedBackSnackbarIcon" class="mr-3" />
                    </template>
                    <template v-slot:default>
                        <div class="white--text">{{ feedBackSnackbarText }}</div>
                    </template>
                    <template v-slot:close>
                        <v-btn text icon @click="feedbackSnackbar = false" class="ml-6">
                            <v-icon v-text="'mdi-close'" />
                        </v-btn>
                    </template>
                </v-alert>
            </v-snackbar>
            <template v-if="$devMode">
                <v-card>
                    <p>$devMode active -> processingActivityModel</p>
                    <pre>{{ processingActivityModel }}</pre>
                </v-card>
            </template>
        </div>
    </div>
</template>

<script>
import http from '@/utils/axios';
import { mapGetters, mapActions } from 'vuex';
import { hasFeature, getDetails } from '@/utils/auth';
import titleMixin from '@/mixins/titleMixin';

//import InfoBar from './InfoBar';
const InfoBar = () => import('./InfoBar');
import {
    parseISO,
    dateTypes,
    formatDate,
    formatDistanceFrom,
} from '@/utils/dateFns';
// eslint-disable-next-line
// import * as easings from 'vuetify/es5/util/easing-patterns'

const Step1 = () => import('./Step1.vue');
const Step2 = () => import('./Step2.vue');
const Step3 = () => import('./Step3.vue');
const Step4 = () => import('./Step4.vue');
const Step5 = () => import('./Step5.vue');
const Step6 = () => import('./Step6.vue');
const Step7 = () => import('./Step7.vue');

const LEA_VVT_LIST_FILTER = 'LEA_VVT_LIST_FILTER';

export default {
    name: 'ProcessEditor',
    $_veeValidate: {
        validator: 'new', // see https://github.com/baianat/vee-validate/issues/1547
    },
    title() {
        return this.processingActivityHeadline;
    },
    props: {
        create: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Boolean,
            default: false,
        },
        leaMaster: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [titleMixin],
    components: {
        Step1,
        Step2,
        Step3,
        Step4,
        Step5,
        Step6,
        Step7,
        InfoBar,
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    data() {
        return {
            feedbackSnackbar: false,
            feedBackSnackbarText: null,
            feedBackSnackbarIcon: null,
            feedBackSnackbarColor: 'primary',
            routeId: this.$route.params.id,
            // routeCompanies: this.$route.params.companies,
            routeMark: this.$route.query.mark,
            routeWorkflowTaskId: this.$route.query.workflow,
            steps: 7,
            modifiedByUser: false,
            formScopes: [
                'form-step-1',
                'form-step-2',
                'form-step-3',
                'form-step-4',
                'form-step-5',
                'form-step-6',
                'form-step-7'
            ],
            baseConfirmation: {
                dialog: false,
                title: '',
                text: '',
                confirmationText: '',
                cancelText: null,
                buttonColorConfirm: '',
                fnConfirm: null,
                fnCancel: null,
                dismissible: true,
                showCancel: true,
                loading: false
            },
            stepperModel: this.$route.query.step || 1,
            validationStep: this.$route.query.step || 1,
            stepErrors: [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
            ],
            processingActivityItem: null,
            recordHasUpdated: false,
            updatedTimeLeft: null,
            updatedTime: null,
            helpModeField: null,
            forcedLanguage: null,
            mobileBreakpoint: 1220,
            unsubscribeStoreWatcher: null,
            trackModification: true,
            timerInterval: null
        };
    },
    computed: {
        ...mapGetters({
            getEventStore: 'eventStore/getDataByKey',
            getCompanyGroupSettings: 'companyGroupSettings/get',
            activityHasActiveWorkflowTask: 'tasks/hasActiveWorkflowTasks',
            editorMode: 'processingActivityModel/getEditorMode',
            templateItem: 'templates/getTemplate',
            getCompanyById: 'companies/getById',
            processingActivityModel: 'processingActivityModel/getModel',
            helpMode: 'processingActivityModel/getHelpModeState',
            workflowMode: 'processingActivityModel/getWorkflowModeState',
            get: 'processingActivityModel/getProperty',
            getAllChildModelItems: 'processingActivityModel/getAllChildModelItems',
            currentUiLanguage: 'languageSettings/getCurrentLanguage',
            internalKeywordItems: 'companyInternalKeywords/getItems',
            companyToolItems: 'tools/getItems',
            companyGroupsOfPersonGroupItems: 'groupsOfPersons/getItems',
            companyPersonalDataCategoryItems: 'personalDataCategories/getItems',
            companyPersonalDataItems: 'personalData/getItems',
            companyNationalDataProtectionLawItems: 'nationalDataProtectionLaw/getItems',
            companyDeletionPractiseItems: 'deletionPractise/getGdprItems',
            companyTypeOfInternalRecipientItems: 'internalRecipientCategories/getItems',
            companyAppropriateSafeguardsItems: 'appropriateSafeguards/getItems',
            companyTypeOfExternalRecipientItems: 'externalRecipientCategories/getItems',
            getMercureActivityChangedByOtherNotifications: 'mercure/getMercureActivityChangedByOtherNotifications'
        }),
        selectedTools() {
            return this.get('tools');
        },
        hasWorkflowButtonBadget () {
            return this.get('canEditWorkflow') && this.activityHasActiveWorkflowTask;
        },
        getMercureActivityUpdate () {
            return this.getMercureActivityChangedByOtherNotifications(this.get('id'), getDetails().id);
        },
        smallButton () {
            return this.$vuetify.breakpoint.width < this.mobileBreakpoint;
        },
        tabsStepperModel: {
            get() {
                return this.stepperModel - 1;
            },
            set(val) {
                this.setHelpModeByStep(val + 1);
                this.stepperModel  = val + 1;
            }
        },
        editorColumns () {
            if (!this.helpMode && !this.workflowMode) {
                return 12;
            }
            if (this.$vuetify.breakpoint.xl) {
                return 9;
            }
            if (this.$vuetify.breakpoint.lgAndUp) {
                return 8;
            }
            return 12;
        },
        sidebarColumns () {
            if (!this.helpMode && !this.workflowMode) {
                return 12;
            }
            if (this.$vuetify.breakpoint.xl) {
                return 3;
            }
            if (this.$vuetify.breakpoint.lgAndUp) {
                return 4;
            }
            return 12;
        },
        layoutHeadline() {
            if (this.create && this.template) {
                return this.$i18n.t('vvtProcessEditor.template.create.title');
            }
            if (!this.create && this.template) {
                return this.$i18n.t('vvtProcessEditor.template.edit.title');
            }
            if (this.create && !this.template) {
                return this.$i18n.t('vvtProcessEditor.create.title');
            }
            if (!this.create && !this.template) {
                return this.$i18n.t('vvtProcessEditor.edit.title');
            }
            return '';
        },
        updatedAt() {
            return this.get('updatedAt');
        },
        processingActivityHeadline() {
            const str = this.get('internalId')
                ? `${this.get('internalId')} | ${this.processingActivityName} `
                : this.processingActivityName;
            if (!str) {
                return this.layoutHeadline;
            }
            return this.layoutHeadline + ': ' + str;
        },
        processingActivityName() {
            return this.get('name') ? this.get('name') : '';
        },
        countryCode() {
            const company = this.getCompanyById(this.get('company'));
            if (company && company.countryCode) {
                return company.countryCode;
            }
            return null;
        },
        company() {
            const company = this.getCompanyById(this.get('company'));
            if (!company || !company.id) {
                return null;
            }
            return company;
        },
        recordLanguage() {
            return this.get('language') ? this.get('language') : this.currentUiLanguage;
        },
        toggleWorkflowTooltip() {
            if (!this.workflowEnabled) {
                return this.$t('vvtProcessEditor.workflowToolTip.disabled');
            }
            if (this.workflowMode) {
                return this.$t('vvtProcessEditor.workflowToolTip.opened');
            }
            return this.$t('vvtProcessEditor.workflowToolTip.closed');
        },
        toggleHelpTooltip() {
            if (this.helpMode) {
                return this.$t('vvtProcessEditor.helpToolTip.opened');
            }
            return this.$t('vvtProcessEditor.helpToolTip.closed');
        },
        hasAccessToWorkflow() {
            return hasFeature('WORKFLOW') && this.getCompanyGroupSettings('useWorkflow');
        },
        workflowEnabled() {
            return this.hasAccessToWorkflow && this.editorMode === 'singleEditMode';
        },
        canEdit () {
            return this.get('canEdit');
        },
    },
    created() {
        this.clearMercureTopicMessages('processingActivityUpdate');

        this.unsubscribeStoreWatcher = this.$store.subscribe(mutation => {
            let mutationType = mutation.type.split('/');
            let mutationStore = mutationType[0];
            let mutationAction = mutationType[1];
            if (this.trackModification && mutationStore === 'processingActivityModel'
                && mutationAction !== 'CLEAR_MODEL'
                && mutationAction !== 'SET_PROPERTY_SILENT'
                && mutationAction !== 'SET_EDITOR_MODE'
                && mutationAction !== 'REPLACE_UUIDS'
                && mutationAction !== 'REPLACE_ROW_UUIDS'
                && mutationAction !== 'SET_HELP_MODE_STATE'
                && mutationAction !== 'SET_WORKFLOW_MODE_STATE'
                && mutationAction !== 'ADD_PURPOSE_OF_PROCESSING_LEGITIMATE_INTEREST'
                && mutationAction !== 'ADD_CORPORATION_RECIPIENT_LEGITIMATE_INTEREST'
                && mutationAction !== 'ADD_EXTERNAL_RECIPIENT_LEGITIMATE_INTEREST')
            {
                let payloadProperty = mutation.payload && mutation.payload.property ? mutation.payload.property : null;
                if (payloadProperty !== 'updatedAt' && payloadProperty !== 'prevLanguage') {
                    this.modifiedByUser = true;
                }
            }
        });
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        ...mapActions({
            fetchSettings: 'companyGroupSettings/fetch',
            fetchCountries: 'countries/fetch',
            fetchGroupHelpItems: 'companyGroupHelp/fetchList',
            fetchRecordById: 'companyProcessingActivities/fetchRecordById',
            fetchCompaniesByFilter: 'companies/fetchByFilter',
            fetchAllCompanies: 'companies/fetchAll',
            fetchDomainItems: 'domain/fetch',
            fetchDepartmentCategories: 'departmentCategories/fetch',
            fetchTemplateById: 'templates/fetchById',
            fetchCompanyInternalKeywords: 'companyInternalKeywords/fetch',
            fetchLegalityItems: 'legality/fetch',
            fetchFlexibilityClauseItems: 'flexibilityClause/fetch',
            fetchNationalDataProtectionLawItems: 'nationalDataProtectionLaw/fetch',
            fetchNationalLegalObligationItems: 'nationalLegalObligation/fetch',
            fetchRecipientNationalLegalObligationItems: 'recipientNationalLegalObligation/fetch',
            fetchAppropriateSafeguardsItems: 'appropriateSafeguards/fetch',
            fetchToolItems: 'tools/fetch',
            fetchCompanyLanguage: 'companyLanguage/fetch',
            fetchDeletionPractiseItems: 'deletionPractise/fetch',
            fetchGroupOfPersonItems: 'groupsOfPersons/fetch',
            fetchPersonalDataCategoryItems: 'personalDataCategories/fetch',
            fetchPersonalDataItems: 'personalData/fetch',
            fetchInternalRecipientItems: 'internalRecipientCategories/fetch',
            fetchExternalRecipientItems: 'externalRecipientCategories/fetch',
            fetchVendorItems: 'vendor/fetch',
            fetchDataSourceItems: 'dataSource/fetch',
            fetchBlacklist: 'blacklist/fetch',
            fetchTom: 'tom/fetchItems',
            setEditorMode: 'processingActivityModel/setEditorMode',
            setLeaMaster: 'processingActivityModel/setLeaMaster',
            setProperty: 'processingActivityModel/setProperty',
            replaceModelUuids: 'processingActivityModel/replaceModelUuids',
            replaceModelRowUuids: 'processingActivityModel/replaceModelRowUuids',
            persistProcessingActivity: 'companyProcessingActivities/persistProcessingActivity',
            mergeProcessingActivityModel: 'processingActivityModel/mergeModel',
            toggleHelpMode: 'processingActivityModel/toggleHelpModeState',
            toggleWorkflow: 'processingActivityModel/toggleWorkflowModeState',
            setWorkflowModeState: 'processingActivityModel/setWorkflowModeState',
            fetchWorkflowTasks: 'tasks/fetchWorkflowTasksByRecordId',
            clearModel: 'processingActivityModel/clearModel',
            setUiLanguage: 'languageSettings/setLanguage',
            resetUiLanguageToLastFetch: 'languageSettings/resetLanguage',
            //
            clearCountryItems: 'countries/clearItems',
            clearToolItems: 'tools/clearItems',
            clearDomainItems: 'domain/clearItems',
            clearDataSourceItems: 'dataSource/clearItems',
            clearGroupOfPersonItems: 'groupsOfPersons/clearItems',
            clearPersonalDataCategoryItems: 'personalDataCategories/clearItems',
            clearPersonalDataItems: 'personalData/clearItems',
            clearLegalityItems: 'legality/clearItems',
            clearNationalLegalObligationItems: 'nationalLegalObligation/clearItems',
            clearFlexibilityClauseItems: 'flexibilityClause/clearItems',
            clearNationalDataProtectionLawItems: 'nationalDataProtectionLaw/clearItems',
            clearLegitimateInterestCategoryItems: 'legitimateInterest/clearItems',
            clearDeletionPractiseItems: 'deletionPractise/clearItems',
            clearInternalRecipientsItems: 'internalRecipientCategories/clearItems',
            clearRecipientNationalLegalObligationItems: 'recipientNationalLegalObligation/clearItems',
            clearAppropriateSafeguardsItems: 'appropriateSafeguards/clearItems',
            clearExternalRecipientsItems: 'externalRecipientCategories/clearItems',
            clearVendorItems: 'vendor/clearItems',
            clearMercureTopicMessages: 'mercure/clearTopicMessages',
            fetchDetailedToolItems: 'tools/fetchDetailed',
            clearDetailedToolItems: 'tools/clearDetailedItems',
        }),
        fetchToolDetails () {
            if(this.selectedTools && this.selectedTools.length) {
                this.$wait.start('fetch tools details');
                this.fetchDetailedToolItems({tools: this.selectedTools}).then(() => {
                    this.$wait.end('fetch tools details');
                })
            } else {
                this.clearDetailedToolItems();
            }
        },
        getLanguageBasedPayload() {
            let payload = {
                fetchMasterItems: this.leaMaster,
                force: this.leaMaster
            };

            if (this.create) {
                return { ...payload, ...{
                        forcedLanguage: this.forcedLanguage ? this.forcedLanguage : this.recordLanguage
                    }
                };
            }

            if (this.forcedLanguage) {
                return { ...payload, ...{
                        forcedLanguage: this.create ? this.recordLanguage : this.forcedLanguage
                    }
                };
            }

            return payload;
        },
        async fetchLanguageBasedItems() {
            await Promise.all([
                this.fetchSettings(this.getLanguageBasedPayload()),
                this.fetchGroupHelpItems(this.getLanguageBasedPayload()),
                this.fetchCountries(this.getLanguageBasedPayload()),
                this.fetchAllCompanies(this.getLanguageBasedPayload()),
                this.fetchCompaniesByFilter(this.getLanguageBasedPayload()),
                this.fetchCompanyLanguage(this.getLanguageBasedPayload()),
                this.fetchDepartmentCategories(this.getLanguageBasedPayload()),
                this.fetchDomainItems(this.getLanguageBasedPayload()),
                this.fetchLegalityItems(this.getLanguageBasedPayload()),
                this.fetchFlexibilityClauseItems(this.getLanguageBasedPayload()),
                this.fetchNationalDataProtectionLawItems(this.getLanguageBasedPayload()),
                this.fetchNationalLegalObligationItems(this.getLanguageBasedPayload()),
                this.fetchRecipientNationalLegalObligationItems(this.getLanguageBasedPayload()),
                this.fetchAppropriateSafeguardsItems(this.getLanguageBasedPayload()),
                this.fetchDeletionPractiseItems(this.getLanguageBasedPayload()),
                this.fetchGroupOfPersonItems(this.getLanguageBasedPayload()),
                this.fetchPersonalDataCategoryItems(this.getLanguageBasedPayload()),
                this.fetchPersonalDataItems(this.getLanguageBasedPayload()),
                this.fetchInternalRecipientItems(this.getLanguageBasedPayload()),
                this.fetchExternalRecipientItems(this.getLanguageBasedPayload()),
                this.fetchVendorItems(this.getLanguageBasedPayload()),
                this.fetchDataSourceItems(this.getLanguageBasedPayload()),
                this.fetchBlacklist(this.getLanguageBasedPayload())
            ]);
            if (this.getCompanyGroupSettings('useCompanyTomInRecord')) {
                await this.fetchTom(this.getLanguageBasedPayload());
            }
        },

        /**
         *
         */
        async fetchData() {
            this.$wait.start('pageLoader');
            this.trackModification = false;

            this.setEditorMode({
                create: this.create,
                template: this.template,
                numOfCompanies: 0,
            });
            
            if (this.routeId && !this.create && this.template) {
                // edit existing template or lea master
                await this.fetchTemplateById({
                    templateId: this.routeId,
                    forcedLanguage: this.currentUiLanguage,
                });
            }

            if (this.routeId && !this.create && !this.template) {
                // edit record
                await this.fetchProcessingActivity(this.routeId).then(data => {
                    if (
                        data.language &&
                        data.language !== this.currentUiLanguage
                    ) {
                        this.setForcedRecordLanguage(data.language);
                    }
                    this.fetchWorkflowTasks(this.routeId);
                });
            }

            await Promise.all([
                this.fetchToolItems({
                    fetchMasterItems: this.leaMaster,
                    force: this.leaMaster
                }),
                this.fetchCompanyInternalKeywords(),
                this.fetchLanguageBasedItems()
            ]);

            await this.setProcessModel();

            this.setLeaMaster(this.leaMaster);
            this.fetchToolDetails();
            this.dataFetched();
            this.$wait.end('pageLoader');
        },

        async fetchProcessingActivity(processingActivityId) {
            return new Promise(resolve => {
                this.fetchRecordById(processingActivityId).then(record => {
                    this.processingActivityItem = record;
                    resolve(record);
                })
            });
        },

        /**
         *
         */
        async setProcessModel() {
            if (!this.create && this.template) {
                // templateEditMode
                await this.mergeProcessingActivityModel(this.templateItem);
            } else if (!this.create && !this.template) {
                // singleEditMode
                await this.mergeProcessingActivityModel(this.processingActivityItem);
            }
            if (this.template) {
                // templateEditMode || templateCreationMode
                this.setProperty({
                    property: 'template',
                    data: true,
                });
            }
        },

        /**
         *
         */
        dataFetched() {
            if (this.updatedAt) {
                this.timerInterval = setInterval(() => {
                    this.updatedTimeLeft = formatDistanceFrom(
                        parseISO(this.updatedAt)
                    );
                    this.updatedTime = formatDate(
                        parseISO(this.updatedAt),
                        dateTypes.fullDateTime
                    );
                }, 1000);
            }
            this.setProperty({
                property: 'language',
                data: this.recordLanguage,
            });
            this.setProperty({
                property: 'prevLanguage',
                data: this.recordLanguage,
            });

            if (this.$route.query.workflow) {
                this.routeWorkflowTaskId = parseInt(this.$route.query.workflow);
                this.setWorkflowModeState(true);
            }
            this.setHelpModeByStep(this.$route.query.step || 1);
            this.clearMercureTopicMessages('processingActivityUpdate');

            this.trackModification = true;
        },

        /**
         *
         */
        validateFormAndSave() {
            this.validateForm().then(valid => {
                if (valid) {
                    this.saveForm().then(saveResult => {
                        if (this.create) {
                            let objRoute = {
                                params: { id: saveResult.id },
                                query: { step: this.stepperModel },
                            };
                            if (this.get('leaMaster')) {
                                objRoute.name = 'AdminVvtEditMaster';
                            } else if (!this.get('leaMaster') && this.template) {
                                objRoute.name = 'SettingsVvtGroupTemplatesEdit';
                            } else {
                                objRoute.name = 'VvtEditProcessingActivity';
                            }
                            this.$router.replace(objRoute);
                        }
                        this.feedBackSnackbarText = this.$t('vvtProcessEditor.saveFeedback');
                        this.feedBackSnackbarColor = 'success';
                        this.feedBackSnackbarIcon = 'mdi-check';
                        this.feedbackSnackbar = true
                    });
                }
            });
        },
        validateForm(scrollToTop = true) {
            if (scrollToTop) {
                window.scrollTo(0, 0);
            }
            return new Promise(resolve => {
                this.$validator.validate().then(valid => {
                    if (!valid) {
                        this.showStepErrors(this.errors.items).then(() => {
                            var errorElement = this.$el.querySelector(
                                '.v-input--has-state.error--text'
                            );
                            this.$scrollTo(errorElement, 600, {
                                offset: -200,
                            });
                        });
                    } else {
                        this.hideAllStepErrors();
                    }
                    resolve(valid);
                });
            });
        },
        saveForm() {
            return new Promise(resolve => {
                /**
                 * Hier holen wir uns alle neuen uuids aller genutzten Item-Stores
                 */
                const newToolItems = this.$store.getters['tools/getUuidItems'];
                const newKeywordItems = this.$store.getters['companyInternalKeywords/getUuidItems'];
                const newInternalRecipientItems = this.$store.getters['internalRecipientCategories/getUuidItems'];
                const newGroupOfPersonItems = this.$store.getters['groupsOfPersons/getUuidItems'];
                const newPersonalDataItems = this.$store.getters['personalData/getUuidItems'];
                const newPersonalDataCategoryItems = this.$store.getters['personalDataCategories/getUuidItems'];
                const newNationalDataProtectionLawItems = this.$store.getters['nationalDataProtectionLaw/getUuidItems'];
                const newDeletionPractiseGdprItems = this.$store.getters['deletionPractise/getGdprUuidItems'];
                const newDeletionPractiseNationalItems = this.$store.getters['deletionPractise/getNationalUuidItems'];
                const newAppropriateSafeguardsItems = this.$store.getters['appropriateSafeguards/getUuidItems'];
                const newExternalRecipientsItems = this.$store.getters['externalRecipientCategories/getUuidItems'];

                const persistModel = {
                    ...this.processingActivityModel,
                    newToolItems,
                    newKeywordItems,
                    newGroupOfPersonItems,
                    newPersonalDataItems,
                    newPersonalDataCategoryItems,
                    newNationalDataProtectionLawItems,
                    newDeletionPractiseGdprItems,
                    newDeletionPractiseNationalItems,
                    newAppropriateSafeguardsItems,
                    newExternalRecipientsItems,
                    newInternalRecipientItems,
                };
                
                if (this.getCompanyGroupSettings('useCompanyTomInRecord')) {

                    const pseudonymisationMeasuresItems = this.$store.getters['tom/getPseudonymisationMeasuresUuidItems'];
                    const encryptionMeasuresItems = this.$store.getters['tom/getEncryptionMeasuresUuidItems'];
                    const confidentialityMeasuresAccessControlItems = this.$store.getters['tom/getConfidentialityMeasuresAccessControlUuidItems'];
                    const confidentialityMeasuresSystemControlItems = this.$store.getters['tom/getConfidentialityMeasuresSystemControlUuidItems'];
                    const confidentialityMeasuresVolumeControlItems = this.$store.getters['tom/getConfidentialityMeasuresVolumeControlUuidItems'];
                    const confidentialityMeasuresSeparationControlItems = this.$store.getters['tom/getConfidentialityMeasuresSeparationControlUuidItems'];
                    const integrityMeasuresDataIntegrityGuaranteeItems = this.$store.getters['tom/getIntegrityMeasuresDataIntegrityGuaranteeUuidItems'];
                    const integrityMeasuresTransmissionControlItems = this.$store.getters['tom/getIntegrityMeasuresTransmissionControlUuidItems'];
                    const integrityMeasuresTransportControlItems = this.$store.getters['tom/getIntegrityMeasuresTransportControlUuidItems'];
                    const integrityMeasuresInputControlItems = this.$store.getters['tom/getIntegrityMeasuresInputControlUuidItems'];
                    const availabilityAndResilienceMeasuresAvailabilityControlItems = this.$store.getters['tom/getAvailabilityAndResilienceMeasuresAvailabilityControlUuidItems'];
                    const availabilityAndResilienceMeasuresRecoverabilityItems = this.$store.getters['tom/getAvailabilityAndResilienceMeasuresRecoverabilityUuidItems'];
                    const availabilityAndResilienceMeasuresReliabilityItems = this.$store.getters['tom/getAvailabilityAndResilienceMeasuresReliabilityUuidItems'];
                    const evaluationOfDataProcessingSecurityMeasuresReviewProcessItems = this.$store.getters['tom/getEvaluationOfDataProcessingSecurityMeasuresReviewProcessUuidItems'];
                    const evaluationOfDataProcessingSecurityMeasuresOrderControlItems = this.$store.getters['tom/getEvaluationOfDataProcessingSecurityMeasuresOrderControlUuidItems'];

                    persistModel.tom = {
                        ...persistModel.tom,
                        pseudonymisationMeasuresItems,
                        confidentialityMeasuresAccessControlItems,
                        confidentialityMeasuresSystemControlItems,
                        confidentialityMeasuresVolumeControlItems,
                        confidentialityMeasuresSeparationControlItems,
                        integrityMeasuresDataIntegrityGuaranteeItems,
                        integrityMeasuresTransmissionControlItems,
                        integrityMeasuresTransportControlItems,
                        integrityMeasuresInputControlItems,
                        availabilityAndResilienceMeasuresAvailabilityControlItems,
                        availabilityAndResilienceMeasuresRecoverabilityItems,
                        availabilityAndResilienceMeasuresReliabilityItems,
                        evaluationOfDataProcessingSecurityMeasuresReviewProcessItems,
                        encryptionMeasuresItems,
                        evaluationOfDataProcessingSecurityMeasuresOrderControlItems,
                    };
                }

                let dispatchResultData = null;

                this.$wait.start('saveActivityForm');
                
                this.persistProcessingActivity(persistModel)
                    .then(dispatchResult => {
                        const statusCode = dispatchResult.response
                            ? dispatchResult.response.status
                            : dispatchResult.status;

                        if (
                            dispatchResult &&
                            (statusCode === 200 || statusCode === 204)
                        ) {
                            dispatchResultData = dispatchResult.data;
                            if (
                                dispatchResultData &&
                                !this.processingActivityModel.id
                            ) {
                                this.setProperty({
                                    property: 'id',
                                    data: dispatchResultData.id,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.updatedAt
                            ) {
                                this.setProperty({
                                    property: 'updatedAt',
                                    data: dispatchResultData.updatedAt,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.status
                            ) {
                                this.setProperty({
                                    property: 'status',
                                    data: dispatchResultData.status,
                                });
                            }
                            if (
                                dispatchResultData &&
                                Object.prototype.hasOwnProperty.call(dispatchResultData, 'authorityReportRelevance')
                            ) {
                                this.setProperty({
                                    property: 'authorityReportRelevance',
                                    data: dispatchResultData.authorityReportRelevance,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newContactItems
                            ) {
                                this.replaceModelUuids({
                                    stateModelProperty: 'contactItems',
                                    items: dispatchResultData.newContactItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newKeywordItems
                            ) {
                                this.$store.dispatch('companyInternalKeywords/setItems', this.$setIdByClientId(this.internalKeywordItems, dispatchResultData.newKeywordItems));
                                this.replaceModelUuids({
                                    stateModelProperty: 'internalKeywords',
                                    items: dispatchResultData.newKeywordItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newToolItems
                            ) {
                                this.$store.dispatch('tools/setItems', this.$setIdByClientId(this.companyToolItems, dispatchResultData.newToolItems));
                                this.replaceModelUuids({
                                    stateModelProperty: 'tools',
                                    items: dispatchResultData.newToolItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newGroupOfPersonItems
                            ) {
                                this.$store.dispatch('groupsOfPersons/setItems', this.$setIdByClientId(this.companyGroupsOfPersonGroupItems, dispatchResultData.newGroupOfPersonItems));
                                this.replaceModelUuids({
                                    stateModelProperty: 'groupsOfPersons',
                                    items:
                                        dispatchResultData.newGroupOfPersonItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newPersonalDataItems
                            ) {
                                this.$store.dispatch('personalData/setItems', this.$setIdByClientId(this.companyPersonalDataItems, dispatchResultData.newPersonalDataItems));
                                this.replaceModelUuids({
                                    stateModelProperty: 'groupsOfPersons',
                                    stateModelSubProperty: 'personalData',
                                    items:
                                        dispatchResultData.newPersonalDataItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'groupsOfPersons',
                                    stateModelSubProperty: 'encryptedPersonalData',
                                    items:
                                        dispatchResultData.newPersonalDataItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'groupsOfPersons',
                                    stateModelSubProperty: 'pseudonymisedPersonalData',
                                    items:
                                        dispatchResultData.newPersonalDataItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'groupsOfPersons',
                                    stateModelSubProperty: 'anonymisedPersonalData',
                                    items:
                                        dispatchResultData.newPersonalDataItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'deletionPractise',
                                    stateModelSubProperty: 'personalData',
                                    items:
                                        dispatchResultData.newPersonalDataItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'internalRecipients',
                                    stateModelSubProperty: 'personalData',
                                    stateModelSubChildProperty: 'personalData',
                                    items: dispatchResultData.newPersonalDataItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'corporationRecipients',
                                    stateModelSubProperty: 'personalData',
                                    stateModelSubChildProperty: 'personalData',
                                    items: dispatchResultData.newPersonalDataItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'externalRecipients',
                                    stateModelSubProperty: 'personalData',
                                    stateModelSubChildProperty: 'personalData',
                                    items: dispatchResultData.newPersonalDataItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newPersonalDataCategoryItems
                            ) {
                                this.$store.dispatch('personalDataCategories/setItems', this.$setIdByClientId(this.companyPersonalDataCategoryItems, dispatchResultData.newPersonalDataCategoryItems));
                                this.replaceModelUuids({
                                    stateModelProperty: 'groupsOfPersons',
                                    stateModelSubProperty: 'personalDataCategory',
                                    items:
                                        dispatchResultData.newPersonalDataCategoryItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'deletionPractise',
                                    stateModelSubProperty: 'personalDataCategory',
                                    items:
                                        dispatchResultData.newPersonalDataCategoryItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'internalRecipients',
                                    stateModelSubProperty: 'personalDataCategory',
                                    items: dispatchResultData.newPersonalDataCategoryItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'corporationRecipients',
                                    stateModelSubProperty: 'personalDataCategory',
                                    items: dispatchResultData.newPersonalDataCategoryItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'externalRecipients',
                                    stateModelSubProperty: 'personalDataCategory',
                                    items: dispatchResultData.newPersonalDataCategoryItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newNationalDataProtectionLawItems
                            ) {
                                this.$store.dispatch('nationalDataProtectionLaw/setItems', this.$setIdByClientId(this.companyNationalDataProtectionLawItems, dispatchResultData.newNationalDataProtectionLawItems));
                                this.replaceModelUuids({
                                    stateModelProperty: 'purposeOfProcessing',
                                    stateModelSubProperty:
                                        'nationalDataProtectionLaw',
                                    items:
                                        dispatchResultData.newNationalDataProtectionLawItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'corporationRecipients',
                                    stateModelSubProperty:
                                        'nationalDataProtectionLaw',
                                    items:
                                        dispatchResultData.newNationalDataProtectionLawItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'externalRecipients',
                                    stateModelSubProperty:
                                        'nationalDataProtectionLaw',
                                    items:
                                        dispatchResultData.newNationalDataProtectionLawItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newDeletionPractiseGdprItems
                            ) {
                                this.$store.dispatch('deletionPractise/setItems', this.$setIdByClientId(this.companyDeletionPractiseItems, dispatchResultData.newDeletionPractiseGdprItems));
                                this.replaceModelUuids({
                                    stateModelProperty: 'deletionPractise',
                                    stateModelSubProperty: 'deletionPractiseGdpr',
                                    items: dispatchResultData.newDeletionPractiseGdprItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newTypeOfInternalRecipientItems
                            ) {
                                this.$store.dispatch('internalRecipientCategories/setItems', this.$setIdByClientId(this.companyTypeOfInternalRecipientItems, dispatchResultData.newTypeOfInternalRecipientItems));
                                this.replaceModelUuids({
                                    stateModelProperty: 'internalRecipients',
                                    stateModelSubProperty: 'typeOfRecipient',
                                    items: dispatchResultData.newTypeOfInternalRecipientItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newInternalRecipientPersonalDataItems
                            ) {
                                this.replaceModelUuids({
                                    stateModelProperty: 'internalRecipients',
                                    stateModelSubProperty: 'personalData',
                                    items: dispatchResultData.newInternalRecipientPersonalDataItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newCorporationRecipientPersonalDataItems
                            ) {
                                this.replaceModelUuids({
                                    stateModelProperty: 'corporationRecipients',
                                    stateModelSubProperty: 'personalData',
                                    items: dispatchResultData.newCorporationRecipientPersonalDataItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newExternalRecipientPersonalDataItems
                            ) {
                                this.replaceModelUuids({
                                    stateModelProperty: 'externalRecipients',
                                    stateModelSubProperty: 'personalData',
                                    items: dispatchResultData.newExternalRecipientPersonalDataItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newAppropriateSafeguardsItems
                            ) {
                                this.$store.dispatch('appropriateSafeguards/setItems', this.$setIdByClientId(this.companyAppropriateSafeguardsItems, dispatchResultData.newAppropriateSafeguardsItems));
                                this.replaceModelUuids({
                                    stateModelProperty: 'corporationRecipients',
                                    stateModelSubProperty:
                                        'appropriateSafeguards',
                                    items:
                                        dispatchResultData.newAppropriateSafeguardsItems,
                                });
                                this.replaceModelUuids({
                                    stateModelProperty: 'externalRecipients',
                                    stateModelSubProperty:
                                        'appropriateSafeguards',
                                    items:
                                        dispatchResultData.newAppropriateSafeguardsItems,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newTypeOfExternalRecipientItems
                            ) {
                                this.$store.dispatch('externalRecipientCategories/setItems', this.$setIdByClientId(this.companyTypeOfExternalRecipientItems, dispatchResultData.newTypeOfExternalRecipientItems));
                                this.replaceModelUuids({
                                    stateModelProperty: 'externalRecipients',
                                    stateModelSubProperty: 'typeOfRecipient',
                                    items:
                                        dispatchResultData.newTypeOfExternalRecipientItems,
                                });
                            }

                            if (
                                dispatchResultData &&
                                dispatchResultData.newGroupOfPersons
                            ) {
                                this.replaceModelRowUuids({
                                    property: 'groupsOfPersons',
                                    items: dispatchResultData.newGroupOfPersons,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newPurposeOfProcessing
                            ) {
                                this.replaceModelRowUuids({
                                    property: 'purposeOfProcessing',
                                    items:
                                        dispatchResultData.newPurposeOfProcessing,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newDeletionPractices
                            ) {
                                this.replaceModelRowUuids({
                                    property: 'deletionPractise',
                                    items:
                                        dispatchResultData.newDeletionPractices,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newInternalRecipients
                            ) {
                                this.replaceModelRowUuids({
                                    property: 'internalRecipients',
                                    items:
                                        dispatchResultData.newInternalRecipients,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newCorporationRecipients
                            ) {
                                this.replaceModelRowUuids({
                                    property: 'corporationRecipients',
                                    items:
                                        dispatchResultData.newCorporationRecipients,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newExternalRecipients
                            ) {
                                this.replaceModelRowUuids({
                                    property: 'externalRecipients',
                                    items:
                                        dispatchResultData.newExternalRecipients,
                                });
                            }
                            if (
                                dispatchResultData &&
                                dispatchResultData.newBlacklistQuestionResultItems
                            ) {
                                this.replaceModelUuids({
                                    stateModelProperty: 'blacklistQuestionResult',
                                    items: dispatchResultData.newBlacklistQuestionResultItems,
                                });
                            }
                            this.setProperty({
                                property: 'prevLanguage',
                                data: dispatchResultData.locale,
                            });

                            this.animateUpdateTime();
                        }
                        this.$wait.end('saveActivityForm');
                        this.modifiedByUser = false;
                        resolve(dispatchResultData);
                    })
                    .catch(() => {
                        this.$wait.end('saveActivityForm');
                    });
            });
        },
        /**
         *
         */
        showStepErrors(arrError) {
            return new Promise(resolve => {
                const errorScopes = [];
                for (const errorObject of arrError) {
                    const stepNum = parseInt(
                        errorObject.scope.replace(/^\D+/g, '')
                    );
                    if (!errorScopes.includes(stepNum)) {
                        errorScopes.push(stepNum);
                    }
                }
                for (const i in this.stepErrors) {
                    const stepNum = parseInt(i) + 1;
                    this.$set(
                        this.stepErrors,
                        i,
                        errorScopes.includes(stepNum)
                    );
                }
                this.stepperModel = errorScopes[0];
                
                this.feedBackSnackbarText = this.$t('genericValidationError.message');
                this.feedBackSnackbarColor = 'error';
                this.feedBackSnackbarIcon = 'mdi-alert-circle-outline';
                this.feedbackSnackbar = true

                setTimeout(() => {
                    resolve();
                }, 300);
            });
        },
        /**
         *
         */
        hideAllStepErrors() {
            for (const i in this.stepErrors) {
                this.$set(this.stepErrors, i, false);
            }
        },
        animateUpdateTime() {
            this.recordHasUpdated = true;
            setTimeout(() => {
                this.recordHasUpdated = false;
            }, 2000);
        },
        async setForcedRecordLanguage(val) {
            this.forcedLanguage = val;
            this.setUiLanguage(val);
            return val;
        },
        onChangedRecordLanguage(val) {
            this.validateForm(false).then(valid => {
                if (valid) {
                    this.$wait.start('pageLoader');
                    this.setProperty({ property: 'language', data: val });
                    this.saveForm().then(saveResult => {
                        this.setForcedRecordLanguage(val).then(() => {
                            this.fetchLanguageBasedItems().then(() => {
                                if (
                                    this.routeId &&
                                    !this.create &&
                                    !this.template
                                ) {
                                    // edit record
                                    this.fetchProcessingActivity(
                                        this.routeId
                                    ).then(() => {
                                        this.setProcessModel();
                                        this.setProperty({
                                            property: 'prevLanguage',
                                            data: this.recordLanguage,
                                        });
                                        this.$wait.end('pageLoader');
                                    });
                                } else if(this.routeId && this.template && !this.create) {
                                    // edit template or master
                                    this.fetchTemplateById({
                                        templateId: this.routeId,
                                        forcedLanguage: this.recordLanguage,
                                    }).then(() => {
                                        this.setProcessModel();
                                        this.setProperty({
                                            property: 'prevLanguage',
                                            data: this.recordLanguage,
                                        });
                                        this.$wait.end('pageLoader');
                                    });
                                } else {
                                    // create record, template or master
                                    let routeName = 'VvtEditProcessingActivity';
                                    if (this.get('leaMaster')) {
                                        routeName = 'AdminVvtEditMaster';
                                    } else if (!this.get('leaMaster') && this.template) {
                                        routeName = 'SettingsVvtGroupTemplatesEdit';
                                    }
                                    this.$router.replace({
                                        name: routeName,
                                        params: { id: saveResult.id },
                                        query: { step: this.stepperModel },
                                    });
                                    this.$wait.end('pageLoader');
                                }
                            });
                        });
                    });
                }
            });
        },
        showCancelDialog () {
            this.baseConfirmation.title = this.$t('vvtProcessEditor.backConfirmationDialog.title');
            this.baseConfirmation.text = this.$t('vvtProcessEditor.backConfirmationDialog.text');
            this.baseConfirmation.confirmationText = this.$t('vvtProcessEditor.backConfirmationDialog.confirmationText');
            this.baseConfirmation.cancelText = this.$t('vvtProcessEditor.backConfirmationDialog.cancelText');
            this.baseConfirmation.buttonColorConfirm = 'success';
            this.baseConfirmation.buttonColorCancel = 'error';
            this.baseConfirmation.dismissible = true;
            this.baseConfirmation.showCancel = true;
            this.baseConfirmation.loading = false;
            this.baseConfirmation.fnConfirm = () => {
                this.validateFormAndSave();
            };
            this.baseConfirmation.fnCancel = () => {
                this.goBack();
            };
            this.baseConfirmation.dialog = true;
        },
        goBack () {
            if (this.leaMaster) {
                this.$router.push({ name: 'AdminVvtMaster' });
                
            } else if (this.template) {
                this.$router.push({ name: 'SettingsVvtGroupTemplatesList' });
            } else {
                let params = {};
                let selectedCompanyId = this.getEventStore('selectedCompanyId');
                let storageFilter = localStorage.getItem(LEA_VVT_LIST_FILTER);
                params.group = 'company';
                params.groupId = selectedCompanyId ? parseInt(selectedCompanyId) : null;
                params.filterId = storageFilter ? storageFilter : 'all';
                this.$router.push({ name: 'VvtList', params: params });
            }
        },
        onCancel () {
            if (this.modifiedByUser) {
                this.showCancelDialog();
            } else {
                this.goBack();
            }
        },
        setHelpModeByStep (val) {
            let localPath = 'vvtProcessEditor.steps.' + val;
            this.helpModeField = {
                title: this.$t(localPath + '.tabTitle'),
                text: this.$t(localPath + '.helpMode')
            };
        },
        showMercureUpdateActivityDialog (updatedById) {
            const modifiedByUser = this.modifiedByUser ? 'modified' : 'notModified';
            this.baseConfirmation.title = this.$t('vvtProcessEditor.mercureUpdateActivityDialog.' + modifiedByUser + '.title');
            this.baseConfirmation.confirmationText = this.$t('vvtProcessEditor.mercureUpdateActivityDialog.' + modifiedByUser + '.confirmationText');
            this.baseConfirmation.cancelText = this.$t('vvtProcessEditor.mercureUpdateActivityDialog.' + modifiedByUser + '.cancelText');
            this.baseConfirmation.buttonColorConfirm = 'success';
            this.baseConfirmation.buttonColorCancel = 'error';
            this.baseConfirmation.dismissible = false;
            this.baseConfirmation.showCancel = this.modifiedByUser;
            this.baseConfirmation.loading = true;

            http.get('/api/user/' + updatedById).then((e) => {
                let name = e.data.firstName + ' ' + e.data.lastName;
                this.baseConfirmation.text = this.$t('vvtProcessEditor.mercureUpdateActivityDialog.' + modifiedByUser + '.text', { updatedByUser: name });
                this.baseConfirmation.loading = false;
            });

            
            this.baseConfirmation.fnConfirm = () => {
                this.fetchData();
            };
            this.baseConfirmation.fnCancel = () => {
                if (this.modifiedByUser) {
                    this.baseConfirmation.dialog = false;
                }
            };
            this.baseConfirmation.dialog = true;
            this.clearMercureTopicMessages('processingActivityUpdate');
        },
    },
    watch: {
        getMercureActivityUpdate: {
            handler(to, from) {
                if (to && to.length && to.length !== from.length && to[0] && to[0].updatedBy) {
                    this.showMercureUpdateActivityDialog(to[0].updatedBy);
                }
            },
            deep: true,
        },
        stepperModel(to, from) {
            to = parseInt(to);
            from = parseInt(from);
            if (to === from) {
                return;
            }
            const routeStep = parseInt(this.$route.query.step);
            this.validationStep = to;
            if (routeStep !== to) {
                const query = JSON.parse(JSON.stringify(this.$route.query));
                this.$router.push({ query: { ...query, step: to } });
            }
        },
        selectedTools(to, from) {
            if (to.length !== from.length){
                this.fetchToolDetails();
            }
        },
        $route(to, from) {
            if (to.params.id) {
                this.routeId = to.params.id;
            }
            if (to.params.companies) {
                this.routeCompanies = to.params.companies;
            }
            if (to.query.mark) {
                this.routeMark = to.query.mark;
            }
            if (to.query.step) {
                this.stepperModel = this.validationStep = to.query.step;
            }
            if (to.query.workflow) {
                this.routeWorkflowTaskId = to.query.workflow;
                this.setWorkflowModeState(true);
            }
            if (
                (this.editorMode === 'templateEditMode' ||
                    this.editorMode === 'singleEditMode') &&
                parseInt(from.params.id) !== parseInt(to.params.id)
            ) {
                this.fetchData();
            } else if (
                (this.editorMode === 'singleCreationMode' ||
                    this.editorMode === 'multipleCreationMode') &&
                from.params.companies !== to.params.companies
            ) {
                this.fetchData();
            }
        },
    },
    beforeDestroy() {
        clearInterval(this.timerInterval);
        this.clearMercureTopicMessages('processingActivityUpdate');
        this.resetUiLanguageToLastFetch();
        if (this.forcedLanguage || this.leaMaster) {
            this.forcedLanguage = null;
            this.clearDomainItems();
            this.clearToolItems();
            this.clearCountryItems();
            this.clearDataSourceItems();
            this.clearGroupOfPersonItems();
            this.clearPersonalDataCategoryItems();
            this.clearPersonalDataItems();
            this.clearLegalityItems();
            this.clearNationalLegalObligationItems();
            this.clearFlexibilityClauseItems();
            this.clearNationalDataProtectionLawItems();
            this.clearLegitimateInterestCategoryItems();
            this.clearDeletionPractiseItems();
            this.clearInternalRecipientsItems();
            this.clearRecipientNationalLegalObligationItems();
            this.clearAppropriateSafeguardsItems();
            this.clearExternalRecipientsItems();
            this.clearVendorItems();
        }
        if (this.unsubscribeStoreWatcher) {
            this.unsubscribeStoreWatcher();
        }
        this.clearModel();
        this.routeId = null;
        this.routeMark = null;
        this.routeWorkflowTaskId = null;
        this.stepperModel = 1;
        this.validationStep = 1;
        this.stepErrors = [
            false,
            false,
            false,
            false,
            false,
            false,
            false
        ];
        this.processingActivityItem = null;
    },
};
</script>
